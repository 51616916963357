<div style="margin-top: 10px; margin-left: 20px">
   <h2>Create Article</h2>
</div>

<div class="container-fluid h-100 bg-light text-dark">
   <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
      <div class="row col-lg-6">
         <div class="form-group">
            <label>Headline</label>
            <input
               type="text"
               class="form-control"
               formControlName="headline"
               placeholder="Enter headline"
            />
            <div
               *ngIf="headline.invalid && (headline.dirty || headline.touched)"
               class="error loginerror"
            >
               <div class="bg-danger" *ngIf="headline.errors">
                  Headline Required
               </div>
            </div>
         </div>

         <div class="form-group">
            <label>Article</label>
            <textarea
               type="text"
               class="form-control"
               formControlName="body"
               placeholder="Enter Article"
            ></textarea>
            <div
               *ngIf="body.invalid && (body.dirty || body.touched)"
               class="error loginerror"
            >
               <div class="bg-danger" *ngIf="body.errors">Article Required</div>
            </div>
         </div>

         <div class="form-group">
            <label>Link</label>
            <input
               type="text"
               class="form-control"
               formControlName="link"
               placeholder="Enter Link"
            />
            <div
               *ngIf="link.invalid && (link.dirty || link.touched)"
               class="error loginerror"
            >
               <div class="bg-danger" *ngIf="link.errors">Link Required</div>
            </div>
         </div>

         <div class="form-group">
            <label>Video Link</label>
            <input
               type="text"
               class="form-control"
               formControlName="videolink"
               placeholder="Enter Video Link"
            />
         </div>

         <div class="form-group form-row">
            <mat-form-field appearance="fill">
               <mat-label>Choose a Category</mat-label>
               <mat-select formControlName="category">
                  <mat-option
                     *ngFor="let category of categories"
                     [value]="category.id"
                  >
                     {{ category.name }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </div>

         <div class="form-group form-row">
            <label style="margin-right: 10px">Choose image</label>

            <input
               type="file"
               id="image"
               (change)="onSelectedFile($event)"
               style="margin-left: 10"
            />
         </div>

         <div class="form-group form-row">
            <label style="margin-right: 10px">Send Notification</label>

            <input
               type="radio"
               id="html"
               value="Yes"
               formControlName="notification"
               name="notification"
               (change)="changedValue('Yes')"
            />
              <label for="yes">Yes</label><br />
            <input
               type="radio"
               id="css"
               value="No"
               formControlName="notification"
               name="notification"
               (change)="changedValue('No')"
            />
              <label for="no">No</label><br />
         </div>

         <div class="form-group form-row">
            <label style="margin-right: 10px">Trending?</label>

            <input
               type="radio"
               id="html"
               value="Yes"
               formControlName="trending_expires_at"
               name="trending_expires_at"
               (change)="changedValuetwo('Yes')"
            />
            <label for="yes">Yes</label><br />
            <input
               type="radio"
               id="css"
               value="No"
               formControlName="trending_expires_at"
               name="trending_expires_at"
               (change)="changedValuetwo('No')"
            />
              <label for="no">No</label><br />
         </div>
         <div class="form-group">
            <label>Author</label>
            <input
               type="text"
               class="form-control"
               formControlName="author"
               placeholder=""
            />
            <div
               *ngIf="author.invalid && (author.dirty || author.touched)"
               class="error loginerror"
            >
               <div class="bg-danger" *ngIf="author.errors">
                  Author Required
               </div>
            </div>
         </div>

         <div class="form-group">
            <button
               type="submit"
               [disabled]="!uploadForm.valid"
               class="btn btn-primary"
            >
               <span>Create</span>
            </button>
         </div>
      </div>
   </form>
</div>
