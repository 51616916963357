<div class="bootstrap-wrapper">
   <div class="container">
      <div class="row">
         <div class="offset-md-1">
            <mat-form-field style="margin-top: 20px">
               <mat-label>Filter</mat-label>
               <input
                  matInput
                  (keyup)="applyFilter($event)"
                  placeholder="Ex. Mia"
                  #input
               />
            </mat-form-field>

            <button
               mat-raised-button
               type="submit"
               (click)="newArticle()"
               class="btn btn-primary mb-2 shadow-sm"
               style="position: absolute; right: 20px; margin-top: 30px"
            >
               <span>Create New Category</span>
            </button>

            <div class="mat-elevation-z8">
               <table mat-table [dataSource]="dataSource" matSort>
                  <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

                  <!-- Id Column -->

                  <ng-container matColumnDef="#Id">
                     <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        ID
                     </th>
                     <td mat-cell *matCellDef="let row">{{ row.id }}</td>
                  </ng-container>

                  <!-- Image Column -->

                  <ng-container matColumnDef="Image">
                     <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Image
                     </th>
                     <td mat-cell *matCellDef="let row">
                        <img
                           src="http://13.126.81.92:3000/uploads/category/{{
                              row.image
                           }}"
                           width="100"
                           height="80"
                           alt="Random first slide"
                        />
                     </td>
                  </ng-container>

                  <!-- Title Column -->
                  <ng-container matColumnDef="Name">
                     <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Name
                     </th>
                     <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                  </ng-container>

                  <!-- Action button Column -->
                  <ng-container matColumnDef="Action">
                     <th mat-header-cell *matHeaderCellDef>Action</th>
                     <td mat-cell *matCellDef="let row">
                        <mat-icon
                           id="toolbarMenu"
                           (click)="deleteCategory(row.id)"
                           >delete</mat-icon
                        >
                     </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                     mat-row
                     *matRowDef="let row; columns: displayedColumns"
                  ></tr>

                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                     <td class="mat-cell" colspan="4">
                        No data matching the filter "{{ input.value }}"
                     </td>
                  </tr>
               </table>

               <mat-paginator
                  [pageSizeOptions]="[10, 20, 30, 100]"
               ></mat-paginator>
            </div>
         </div>
      </div>
   </div>
</div>
