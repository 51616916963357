<div class="container-fluid">
   <div class="row no-gutter">
      <!-- The image half -->
      <div class="col-md-6 d-none d-md-flex bg-image"></div>
      <!-- The content half -->
      <div class="col-md-6 bg-light">
         <div class="login d-flex align-items-center py-5">
            <!-- Demo content-->
            <div class="container">
               <div class="row">
                  <div class="col-lg-10 col-xl-7 mx-auto">
                     <div class="container text-center">
                        <img
                           class="img-fluid"
                           style="width: 200px"
                           src="http://13.126.81.92/assets/logo.png"
                        />
                        <form
                           [formGroup]="loginForm"
                           (ngSubmit)="login()"
                           novalidate
                        >
                           <div class="form-group mb-6">
                              <input
                                 matInput
                                 placeholder="Ex. Jon"
                                 formControlName="email"
                                 class="form-control rounded-pill border-0 shadow-sm px-4"
                              />
                           </div>
                           <div class="form-group mb-6">
                              <input
                                 matInput
                                 type="password"
                                 placeholder="Ex. Jon"
                                 formControlName="password"
                                 class="form-control rounded-pill border-0 shadow-sm px-4"
                              />
                           </div>

                           <button
                              color="accent"
                              [disabled]="!loginForm.valid"
                              mat-raised-button
                              type="submit"
                              class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm"
                           >
                              <span>Log in</span>
                           </button>
                           <div
                              class="text-center d-flex justify-content-between mt-4"
                           ></div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <!-- End -->
         </div>
      </div>
      <!-- End -->
   </div>
</div>
