<div style="margin-top: 10px; margin-left: 20px">
   <p>
      <span
         style="
            font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
               Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
               'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-variant-caps: normal;
            letter-spacing: normal;
            orphans: auto;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            white-space: normal;
            widows: auto;
            word-spacing: 0px;
            -webkit-text-size-adjust: auto;
            -webkit-text-stroke-width: 0px;
            text-decoration: none;
            box-sizing: inherit;
            font-weight: 700;
            color: rgb(54, 54, 54);
         "
         >Privacy Policy</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      Raffleza Universal LLP built the Rapid Reads app as a Free app. This
      SERVICE is provided by Raffleza Universal LLP at no cost and is intended
      for use as is.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      This page is used to inform visitors regarding our policies with the
      collection, use, and disclosure of Personal Information if anyone decided
      to use our Service.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      If you choose to use our Service, then you agree to the collection and use
      of information in relation to this policy. The Personal Information that
      we collect is used for providing and improving the Service. We will not
      use or share your information with anyone except as described in this
      Privacy Policy.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      The terms used in this Privacy Policy have the same meanings as in our
      Terms and Conditions, which is accessible at Rapid Reads unless otherwise
      defined in this Privacy Policy.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Information Collection and Use</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      For a better experience, while using our Service, we may require you to
      provide us with certain personally identifiable information. The
      information that we request will be retained by us and used as described
      in this privacy policy.
   </p>
   <div
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
      "
   >
      <p style="box-sizing: inherit; margin: 0px; padding: 0px">
         The app does use third party services that may collect information used
         to identify you.
      </p>
      <p style="box-sizing: inherit; margin: 0px; padding: 0px">
         Link to privacy policy of third party service providers used by the app
      </p>
      <ul
         style="
            box-sizing: inherit;
            margin: 0px;
            padding: 0px;
            list-style-position: initial;
         "
      >
         <li
            style="
               margin: 10px 10px 10px 2.5em;
               box-sizing: inherit;
               padding: 0px;
            "
         >
            <a
               data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/policies/privacy/&source=gmail&ust=1638339103061000&usg=AOvVaw1uGHAx9mKbGc8q79TnRS9c"
               href="https://www.google.com/policies/privacy/"
               rel="noopener noreferrer"
               style="
                  color: rgb(50, 115, 220);
                  box-sizing: inherit;
                  background-color: transparent;
                  text-decoration-line: none;
               "
               target="_blank"
               >Google Play Services</a
            >
         </li>
         <li
            style="
               margin: 10px 10px 10px 2.5em;
               box-sizing: inherit;
               padding: 0px;
            "
         >
            <a
               data-saferedirecturl="https://www.google.com/url?q=https://support.google.com/admob/answer/6128543?hl%3Den&source=gmail&ust=1638339103061000&usg=AOvVaw2OgiDvDmGjfPgOpgL9XYUI"
               href="https://support.google.com/admob/answer/6128543?hl=en"
               rel="noopener noreferrer"
               style="
                  color: rgb(50, 115, 220);
                  box-sizing: inherit;
                  background-color: transparent;
                  text-decoration-line: none;
               "
               target="_blank"
               >AdMob</a
            >
         </li>
         <li
            style="
               margin: 10px 10px 10px 2.5em;
               box-sizing: inherit;
               padding: 0px;
            "
         >
            <a
               data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/about/privacy/update/printable&source=gmail&ust=1638339103061000&usg=AOvVaw0EaqTtKrhUOa4k4WYNSEmS"
               href="https://www.facebook.com/about/privacy/update/printable"
               rel="noopener noreferrer"
               style="
                  color: rgb(50, 115, 220);
                  box-sizing: inherit;
                  background-color: transparent;
                  text-decoration-line: none;
               "
               target="_blank"
               >Facebook</a
            >
         </li>
      </ul>
   </div>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Log Data</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      We want to inform you that whenever you use our Service, in a case of an
      error in the app we collect data and information (through third party
      products) on your phone called Log Data. This Log Data may include
      information such as your device Internet Protocol (&ldquo;IP&rdquo;)
      address, device name, operating system version, the configuration of the
      app when utilizing our Service, the time and date of your use of the
      Service, and other statistics.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Cookies</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      Cookies are files with a small amount of data that are commonly used as
      anonymous unique identifiers. These are sent to your browser from the
      websites that you visit and are stored on your device&apos;s internal
      memory.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      This Service does not use these &ldquo;cookies&rdquo; explicitly. However,
      the app may use third party code and libraries that use
      &ldquo;cookies&rdquo; to collect information and improve their services.
      You have the option to either accept or refuse these cookies and know when
      a cookie is being sent to your device. If you choose to refuse our
      cookies, you may not be able to use some portions of this Service.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Service Providers</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      We may employ third-party companies and individuals due to the following
      reasons:
   </p>
   <ul
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
         list-style-position: initial;
      "
   >
      <li
         style="margin: 10px 10px 10px 2.5em; box-sizing: inherit; padding: 0px"
      >
         To facilitate our Service;
      </li>
      <li
         style="margin: 10px 10px 10px 2.5em; box-sizing: inherit; padding: 0px"
      >
         To provide the Service on our behalf;
      </li>
      <li
         style="margin: 10px 10px 10px 2.5em; box-sizing: inherit; padding: 0px"
      >
         To perform Service-related services; or
      </li>
      <li
         style="margin: 10px 10px 10px 2.5em; box-sizing: inherit; padding: 0px"
      >
         To assist us in analyzing how our Service is used.
      </li>
   </ul>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      We want to inform users of this Service that these third parties have
      access to your Personal Information. The reason is to perform the tasks
      assigned to them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Security</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      We value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it. But
      remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and we cannot guarantee
      its absolute security.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Links to Other Sites</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      This Service may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by us. Therefore, we strongly advise you
      to review the Privacy Policy of these websites. We have no control over
      and assume no responsibility for the content, privacy policies, or
      practices of any third-party sites or services.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Children&rsquo;s Privacy</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      These Services do not address anyone under the age of 13. We do not
      knowingly collect personally identifiable information from children under
      13 years of age. In the case we discover that a child under 13 has
      provided us with personal information, we immediately delete this from our
      servers. If you are a parent or guardian and you are aware that your child
      has provided us with personal information, please contact us so that we
      will be able to do necessary actions.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Changes to This Privacy Policy</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      We may update our Privacy Policy from time to time. Thus, you are advised
      to review this page periodically for any changes. We will notify you of
      any changes by posting the new Privacy Policy on this page.
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      This policy is effective as of 2021-11-20
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      <span
         style="box-sizing: inherit; font-weight: 700; color: rgb(54, 54, 54)"
         >Contact Us</span
      >
   </p>
   <p
      style="
         caret-color: rgb(74, 74, 74);
         color: rgb(74, 74, 74);
         font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', Helvetica, Arial, sans-serif;
         font-size: 16px;
         font-style: normal;
         font-variant-caps: normal;
         font-weight: normal;
         letter-spacing: normal;
         orphans: auto;
         text-align: start;
         text-indent: 0px;
         text-transform: none;
         white-space: normal;
         widows: auto;
         word-spacing: 0px;
         -webkit-text-size-adjust: auto;
         -webkit-text-stroke-width: 0px;
         text-decoration: none;
         box-sizing: inherit;
         margin: 0px;
         padding: 0px;
      "
   >
      If you have any questions or suggestions about our Privacy Policy, do not
      hesitate to contact us at<span class="Apple-converted-space">&nbsp;</span
      ><a
         href="mailto:rapidreads86@gmail.com"
         style="color: rgb(17, 85, 204)"
         target="_blank"
         >rapidreads86@gmail.com</a
      >.
   </p>
</div>
